export function deepMerge<OBJ1 extends OBJ2 = any, OBJ2 = any>(obj1: OBJ1, obj2: OBJ2): OBJ1 & OBJ2 {
    const result = { ...obj1 }

    for (const key in obj2) {
        if (Object.prototype.hasOwnProperty.call(obj2, key)) {
            if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
                result[key] = deepMerge(obj1[key], obj2[key])
            } else {
                // @ts-ignore
                result[key] = obj2[key]
            }
        }
    }

    return result
}
