'use client'

import { DEFAULT_ENV_VARIABLES, RUNTIME_ENV_CONFIG, RuntimeEnvConfig } from '@/config/env'
import { ENV_SCRIPT_ID } from '@tebuto/layout/EnvVariablesScript'
import { deepMerge } from '@tebuto/utility/helpers'
import { ReactNode, createContext, useContext, useEffect, useState } from 'react'

const EnvVariablesClientContext = createContext<RuntimeEnvConfig>(DEFAULT_ENV_VARIABLES as RuntimeEnvConfig)

type EnvClientProviderProps = {
    children: ReactNode
}

export function RuntimeEnvProvider({ children }: EnvClientProviderProps) {
    const [envs, setEnvs] = useState<RuntimeEnvConfig>(DEFAULT_ENV_VARIABLES)

    useEffect(() => {
        const runtimeEnvs = getRuntimeEnv()
        setEnvs(runtimeEnvs)
    }, [])

    return <EnvVariablesClientContext.Provider value={envs}>{children}</EnvVariablesClientContext.Provider>
}

export function useRuntimeEnv(): RuntimeEnvConfig {
    if (EnvVariablesClientContext === undefined) {
        throw new Error('useRuntimeEnv must be used within an RuntimeEnvProvider')
    }

    return useContext(EnvVariablesClientContext)
}

function getRuntimeEnv(): RuntimeEnvConfig {
    const isSSR = typeof window === 'undefined'
    if (isSSR) {
        return deepMerge(DEFAULT_ENV_VARIABLES, RUNTIME_ENV_CONFIG)
    }

    const script = window.document.getElementById(ENV_SCRIPT_ID) as HTMLScriptElement
    if (!script) {
        throw new Error('EnvVariablesScript not found')
    }

    const loadedValues = JSON.parse(script.innerHTML)
    return deepMerge(DEFAULT_ENV_VARIABLES, loadedValues)
}
