// Types

export type RuntimeEnvConfig = {
    flags: {
        registryEnabled: boolean
        appEnabled: boolean
        connectZoomEnabled: boolean
        connectMicrosoftTeamsEnabled: boolean
        connectGoogleCalendarEnabled: boolean
        connectOutlookEnabled: boolean
    }
}

export type LoadedRuntimeEnvConfig = {
    flags: {
        registryEnabled?: boolean
        appEnabled?: boolean
        connectZoomEnabled?: boolean
        connectMicrosoftTeamsEnabled?: boolean
        connectGoogleCalendarEnabled?: boolean
        connectOutlookEnabled?: boolean
    }
}

// Constants

export const DEFAULT_ENV_VARIABLES: RuntimeEnvConfig = {
    flags: {
        registryEnabled: false,
        appEnabled: false,
        connectZoomEnabled: true,
        connectMicrosoftTeamsEnabled: true,
        connectGoogleCalendarEnabled: true,
        connectOutlookEnabled: true
    }
}

export const RUNTIME_ENV_CONFIG: LoadedRuntimeEnvConfig = {
    flags: {
        registryEnabled: getBooleanEnvVariable('FEATURE_FLAG_REGISTRY_ENABLED'),
        appEnabled: getBooleanEnvVariable('FEATURE_FLAG_APP_ENABLED'),
        connectZoomEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_ZOOM_ENABLED'),
        connectMicrosoftTeamsEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_MICROSOFT_TEAMS_ENABLED'),
        connectGoogleCalendarEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_GOOGLE_CALENDAR_ENABLED'),
        connectOutlookEnabled: getBooleanEnvVariable('FEATURE_FLAG_CONNECT_OUTLOOK_ENABLED')
    }
}

// Functions

function getBooleanEnvVariable(name: string): boolean | undefined {
    const value = process.env[name]
    return value ? value === 'true' : undefined
}
