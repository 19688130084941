import { RUNTIME_ENV_CONFIG } from '@/config/env'
import { unstable_noStore as noStore } from 'next/cache'

export const ENV_SCRIPT_ID = 'env-config'

export default function EnvVariablesScript() {
    noStore()
    return (
        <script
            id={ENV_SCRIPT_ID}
            // biome-ignore lint/security/noDangerouslySetInnerHtml: This is a trusted source
            dangerouslySetInnerHTML={{ __html: JSON.stringify(RUNTIME_ENV_CONFIG) }}
        />
    )
}
