'use client'

import { CalendarDaysIcon, ChatBubbleLeftRightIcon, DocumentTextIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { useLoginStatus } from '@tebuto/functions'
import { useRuntimeEnv } from '@tebuto/providers/RuntimeEnvProvider'
import COMPONENT_TEXTS from '@tebuto/translations'
import { Menu, MenuEntry } from '../Menu'

export const Header = () => {
    const isLoggedIn = useLoginStatus()
    const { flags } = useRuntimeEnv()

    const leftItems: MenuEntry[] = [
        { name: COMPONENT_TEXTS.layout.landing.menu.home, href: '/' },
        {
            name: COMPONENT_TEXTS.layout.landing.menu.usage,
            href: '/#termin-management',
            subMenu: {
                items: [
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.appointments.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.appointments.subtitle,
                        href: '/#termin-management',
                        icon: CalendarDaysIcon
                    },
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.clientLinks.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.clientLinks.subtitle,
                        href: '/#teilen-mit-klienten',
                        icon: ChatBubbleLeftRightIcon
                    },
                    {
                        name: COMPONENT_TEXTS.layout.landing.menu.features.communication.title,
                        description: COMPONENT_TEXTS.layout.landing.menu.features.communication.subtitle,
                        href: '/#kommunikation',
                        icon: ChatBubbleLeftRightIcon
                    }
                ],
                cta: [
                    { name: 'Rückruf vereinbaren', href: '/kontakt', icon: PhoneIcon },
                    { name: 'Dokumentation', href: '/dokumentation', icon: DocumentTextIcon }
                ]
            }
        },
        { name: COMPONENT_TEXTS.layout.landing.menu.price, href: '/#preis' },
        { name: COMPONENT_TEXTS.layout.landing.menu.roadmap, href: '/roadmap' },
        { name: COMPONENT_TEXTS.layout.landing.menu.blog, href: '/blog' }
    ]

    const rightItems: MenuEntry[] = []

    if (flags.registryEnabled) {
        rightItems.push({ name: COMPONENT_TEXTS.layout.landing.menu.directory, href: '/verzeichnis', highlight: true })
    }

    if (flags.appEnabled) {
        if (isLoggedIn) {
            rightItems.push({ name: COMPONENT_TEXTS.layout.landing.toApp, href: process.env.NEXT_PUBLIC_APP_BASE_URL as string, isAbsolute: true, highlight: true })
        } else {
            rightItems.push({ name: COMPONENT_TEXTS.layout.landing.menu.register, href: '/registrieren', highlight: true })
            rightItems.push({ name: COMPONENT_TEXTS.layout.landing.menu.login, href: '/login', id: 'login' })
        }
    }

    const additionalMobileItems = [{ name: 'Dokumentation', href: '/dokumentation' }]

    return <Menu id="top" leftItems={leftItems} rightItems={rightItems} baseUrl={`${process.env.NEXT_PUBLIC_LANDING_BASE_URL}`} additionalMobileItems={additionalMobileItems} />
}
